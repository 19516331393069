import { useState, useEffect, useRef } from "react"

const useImagePreload = (path, shouldLoad = true) => {
    const [state, setState] = useState(null)
    const image = useRef();

    useEffect(() => {
        if (shouldLoad && !state) {
            setState('loading')

            image.current = new Image()
            image.current.onload = () => {
                setState('loaded')
            };
            image.current.src = path;
        }

        if (!shouldLoad && state === 'loading') {
            setState(null)
            image.current.src = '';
        }
    }, [path, shouldLoad, state]);

    return state === 'loaded'
}

export default useImagePreload
