import { useState, useEffect, useRef } from 'react'

const useIsVisible = (rootMargin = 300, restoreVisibility = false) => {
    const [isVisible, setIsVisible] = useState(false)
    const ref = useRef();

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true)

                    if (!restoreVisibility) observer.disconnect();
                } else {
                    setIsVisible(false)
                }
            },
            {
                root: null,
                rootMargin: `${rootMargin}px`
            }
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            observer.disconnect();
        }

    }, [rootMargin, ref, restoreVisibility]);

    return {
        ref,
        isVisible
    }
}

export default useIsVisible