import React, { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useLocation, useParams } from 'react-router-dom';
import Map from './Map';
import Loading from 'components/Loading';
import { useLanguage } from 'utils/hooks';

const PageMap = () => {
    const location = useLocation();
    let { slug } = useParams();
    const language = useLanguage();

    console.log(language)

    useEffect(() => {
        document.documentElement.classList[!slug ? 'add' : 'remove']('touch')

        return () => {
            document.documentElement.classList['remove']('touch')
        }

    }, [location])

    const { isLoading, error, data } = useQuery({
        queryKey: [language, 'markers'],
        queryFn: () =>
            fetch(`${process.env.REACT_APP_PUBLIC_API_URL}markers?lang=${language}`).then(res =>
                res.json()
            )
    })

    if (isLoading) return <Loading />

    console.log('MAP')
    console.log(data)

    return (
        <Map data={data} />
    );
};

export default PageMap;