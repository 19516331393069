const trees = [
    {
        marker: {
            x: 43,
            y: 33.5,
            type: 1
        }
    },
    {
        marker: {
            x: 44.6,
            y: 32.5,
            type: 2
        }
    },
    {
        marker: {
            x: 43.5,
            y: 34.5,
            type: 3
        }
    },
    {
        marker: {
            x: 29,
            y: 43.5,
            type: 1
        }
    },
    {
        marker: {
            x: 30,
            y: 44.5,
            type: 1
        }
    },
    {
        marker: {
            x: 28.5,
            y: 44.5,
            type: 2
        },
    },
    {
        marker: {
            x: 69,
            y: 32.5,
            type: 3
        }
    },
    {
        marker: {
            x: 72,
            y: 33,
            type: 1
        }
    },
    {
        marker: {
            x: 68,
            y: 33,
            type: 1
        }
    },
    {
        marker: {
            x: 51.5,
            y: 73.5,
            type: 3
        }
    },
    {
        marker: {
            x: 52,
            y: 74.5,
            type: 1
        }
    },
    {
        marker: {
            x: 53,
            y: 74,
            type: 2
        }
    },
    {
        marker: {
            x: 53,
            y: 63.5,
            type: 1
        }
    },
    {
        marker: {
            x: 51.3,
            y: 61.5,
            type: 3
        },
    },
    {
        marker: {
            x: 58.3,
            y: 53.5,
            type: 1
        }
    },
    {
        marker: {
            x: 59.7,
            y: 54.5,
            type: 1
        }
    },
    {
        marker: {
            x: 59,
            y: 54,
            type: 3
        }
    },
    {
        marker: {
            x: 64,
            y: 72,
            type: 1
        }
    },
    {
        marker: {
            x: 63,
            y: 71,
            type: 3
        }
    },
    {
        marker: {
            x: 55,
            y: 65,
            type: 3
        }
    },
    {
        marker: {
            x: 71,
            y: 41,
            type: 3
        }
    },
    {
        marker: {
            x: 72,
            y: 42,
            type: 1
        },
    },
    {
        marker: {
            x: 45.5,
            y: 51,
            type: 1
        },
    },
    {
        marker: {
            x: 36.3,
            y: 38.7,
            type: 3
        }
    },

]

export default trees