import React from 'react';

const ArrowSimple = () => {
    return (
        <svg viewBox="0 0 29.31 52.7">
            <polyline points="1.43 1.4 26.47 26.99 1.43 51.26" fill="none" stroke="currentColor" strokeWidth="4" />
        </svg>
    );
};

export default ArrowSimple;