import {
    useEffect,
    useCallback,
    useMemo,
    lazy,
    Suspense
} from 'react';
import {
    useNavigate,
    useParams
} from 'react-router-dom';
import cls from 'classnames';
import {
    useTouch,
    useWindowSize
} from 'utils/hooks';
import Loading from 'components/Loading';
import Marker from './Marker';
import PageSpot from 'components/PageSpot';
import { ArrowSimpleIcon } from 'components/Icons';
import styles from './Map.module.sass'
import trees from 'data/trees';
import graves from 'data/graves';
import setTitle from 'utils/setTitle';
import { Logo } from 'components/Icons';


const MapSvg = lazy(() => import('./MapSvg'));



//const mapSize = 1000;
const selectedMarkerWidth = 480
const selectedMarkerHeight = 480

const Map = ({ data }) => {

    const navigate = useNavigate();
    const windowSize = useWindowSize();

    let { slug } = useParams();



    const { locations, streets, stackedLocations } = useMemo(() => {

        const { locations, streets } = data;

        const stackedLocations = [...locations].map((location, index) => ({ ...location, index }));
        let stackFound = true

        while (stackFound === true) {
            const stack = stackedLocations.filter(location => !location.stacked && location.path).reduce((stack, location) => {

                const { x, y } = location.marker

                const otherLocations = stackedLocations.filter(child => {
                    const distance = (Math.sqrt(Math.pow((x - child.marker.x), 2) + Math.pow((y - child.marker.y), 2)))
                    return child.id !== location.id && !child.stacked && child.path && distance < 2.5
                })

                //console.log(otherLocations.length)

                if (!stack || stack.children.length < otherLocations.length) {
                    stack = {
                        parent: location,
                        children: otherLocations
                    }
                }

                return stack
            }, undefined);

            console.log(stack.children.length)

            if (stack && stack.children.length) {
                stack.parent.stacked = {
                    type: 'parent',
                    children: stack.children.length
                }

                const { x, y } = stack.parent.marker

                //location.marker.size = 1;

                stack.children.forEach((child, index) => {
                    child.stacked = {
                        type: 'child',
                        parent: stack.parent.id,
                        index,
                        x,
                        y
                    }
                    //child.marker.size = 1;
                })
            }

            if (!stack || stack.children.length < 1) stackFound = false
        }





        return {
            locations,
            streets,
            stackedLocations
        }
    }, [data])

    console.log(stackedLocations)

    const { mapSize, scaleLevels, selectedMarkerScale } = useMemo(() => {
        const mapSize = Math.min(windowSize.width, windowSize.height) * 3
        const selectedMarkerMaxWidth = windowSize.width - 60
        const selectedMarkerMaxHeight = windowSize.clientHeight / 2 - 90;

        const responsiveScale = Math.min(
            selectedMarkerMaxWidth / selectedMarkerWidth,
            selectedMarkerMaxHeight / selectedMarkerHeight
        )

        //const selectedMarkerScale = 4;
        const selectedMarkerScale = responsiveScale * 3 / 5;
        //const scaleMin = windowSize.width / mapSize / 0.7;
        const scaleMin = 0.5;

        const scaleLevels = [
            scaleMin,
            1,
            1.75,
            3,
            5
        ]

        return { mapSize, scaleLevels, selectedMarkerScale }
    }, [windowSize])

    const {
        mapPosition, setMapPosition, ref, onClickZoom
    } = useTouch({ locations, scaleLevels, mapSize });

    const {
        x,
        y,
        scale,
        rotateX,
        selectedMarkerIndex
    } = mapPosition;

    const scaleLevel = scaleLevels.reduce((level, test) => {
        if (test < scale) level++
        return level
    }, 0)

    console.log('LEVEl: ' + scaleLevel)
    const shouldStack = scaleLevel < 1


    const getMarkerIndex = useCallback((index) => {
        const maxIndex = locations.length - 1
        if (index < 0) index = maxIndex
        if (index > maxIndex) index = 0
        return index
    }, [locations])

    const onClickMarker = useCallback((location) => {
        console.log(location.index + "===" + selectedMarkerIndex)



        if (location.index === selectedMarkerIndex) {

            navigate(location.path)
        } else {


            if (location.stacked && shouldStack) {

                console.log('click stack')

                const x = (location.marker.x - 50) / 100 * -mapSize
                const y = (location.marker.y - 50) / 100 * -mapSize // + 30


                setMapPosition({
                    x,
                    y,
                    scale: scaleLevels[scaleLevels.length - 2],
                    rotateX: 45,
                    selectedMarkerIndex: null
                })



            } else {
                console.log('center marker')
                centerMarker(location.index);
            }



        }

    }, [selectedMarkerIndex, mapSize, shouldStack])

    const onChangeMarker = (direction) => {
        centerMarker(getMarkerIndex(selectedMarkerIndex + direction))
    }

    const centerMarker = useCallback((index) => {
        const spot = locations[index]
        const x = (spot.marker.x - 50) / 100 * -mapSize
        const y = (spot.marker.y - 50) / 100 * -mapSize


        setMapPosition({
            x,
            y,
            scale: scaleLevels[scaleLevels.length - 1],
            rotateX: 45,
            selectedMarkerIndex: index
        })



    }, [locations, scaleLevels, mapSize])

    useEffect(() => {
        if (selectedMarkerIndex) centerMarker(selectedMarkerIndex)
    }, [windowSize, selectedMarkerIndex, centerMarker])







    const closeMarker = () => {
        if (slug) {
            navigate('/')
        }


        setMapPosition({
            ...mapPosition,
            scale: scaleLevels[scaleLevels.length - 3],
            rotateX: 45,
            selectedMarkerIndex: null
        })
    }

    //



    /*
    if (dragging.isDragging) {
        x += dragging.x / scale
        y += dragging.y / scale
    }
    
    dragging.isDragging && console.log(dragging)
    */

    const {
        previousMarker,
        selectedMarker,
        nextMarker
    } = useMemo(() => ({
        selectedMarker: selectedMarkerIndex !== null && locations[selectedMarkerIndex],
        previousMarker: selectedMarkerIndex !== null && locations[getMarkerIndex(selectedMarkerIndex - 1)],
        nextMarker: selectedMarkerIndex !== null && locations[getMarkerIndex(selectedMarkerIndex + 1)]
    }), [locations, selectedMarkerIndex, getMarkerIndex])

    useEffect(() => {
        setTitle(selectedMarker?.title)
    }, [selectedMarker])



    //console.log(locations.filter(item => item.parent).length)
    //console.log(groupedLocations.filter(item => item.parent).length)

    console.log(mapPosition)


    const testLocations = useMemo(() => {
        const test = stackedLocations.map((item, index) => {
            return (
                <Marker
                    key={item.id}
                    type="location"
                    data={item}
                    isVisible={selectedMarkerIndex === null || selectedMarkerIndex === item.index}
                    isSelected={selectedMarkerIndex === item.index}
                    onClick={onClickMarker}
                    shouldStack={shouldStack}
                />
            )
        })


        return test
    }, [selectedMarkerIndex, onClickMarker, stackedLocations, shouldStack])


    const { testStreets, testGraves, testTrees } = useMemo(() => {

        const testStreets = streets.map((item, index) => (
            <Marker
                key={`street${index}`}
                type="street"
                data={item}
                isVisible={selectedMarkerIndex === null && (item.marker.level - 1 <= scaleLevel || item.marker.level === 1)}

            />
        ));

        const testGraves = graves.map((item, index) => (
            <Marker
                key={`grave${index}`}
                type="grave"
                data={item}

            />
        ))

        const testTrees = trees.map((item, index) => (
            <Marker
                key={`tree${index}`}
                type="tree"
                data={item}


            />
        ))

        return {
            testStreets,
            testGraves,
            testTrees
        }

    }, [selectedMarkerIndex, scaleLevel])

    /*
    

                                */

    return (
        <>
            <div
                className={cls(styles.root, {
                    'spot-selected': selectedMarkerIndex !== null,
                    'dragging': mapPosition.isMoving,
                    'svg': true
                })}
                style={{
                    '--map-size': `${mapSize}px`,

                    //'--map-marker-scale': mapSize / 4500 * (1 - scaleLevel / 6)
                    '--map-marker-scale': mapSize / 4500 * (1 - scale / 8),
                    '--map-marker-scale-selected': selectedMarkerScale

                    // '--map-marker-scale': mapSize / 4500 - scale * 0.175

                }}
            >
                <h1 style={{ fontSize: '10px', display: 'none' }} className="test"><span>{JSON.stringify(windowSize)}</span></h1>
                <h1><Logo /><span>Jüdisches Leben in Wandsbek</span></h1>

                <div className="scale" ref={ref} style={{ transform: `scale(${scale})` }}>
                    <div className="rotation" style={{ transform: `rotateX(${rotateX}deg)` }}>
                        <div className="position" style={{ transform: `translate(${x}px, ${y}px)` }}>
                            <Suspense fallback={<Loading />}>

                                <MapSvg />
                                {testStreets}
                                {testGraves}
                                {testTrees}

                                {testLocations}
                            </Suspense>
                        </div>
                    </div>
                </div>

                <button
                    className="previous"
                    onClick={() => onChangeMarker(-1)}>
                    <ArrowSimpleIcon />
                    {previousMarker &&
                        <span>{previousMarker.title}</span>
                    }
                </button>
                <button
                    className="next"
                    onClick={() => onChangeMarker(1)}>
                    {nextMarker &&
                        <span>{nextMarker.title}</span>
                    }
                    <ArrowSimpleIcon />
                </button>
                <button
                    className="zoom-out"
                    onClick={() => onClickZoom(-1)}
                    disabled={scale <= scaleLevels[0]}
                />
                <button
                    className="zoom-in"
                    onClick={() => onClickZoom(1)}
                    disabled={scale >= scaleLevels[scaleLevels.length - 2]}
                />
            </div >
            {(slug || selectedMarkerIndex !== null) &&
                <button
                    className={styles.close}
                    onClick={() => closeMarker()}>
                    <span>zur Karte</span>
                </button>
            }
            <PageSpot
                previewData={selectedMarker}
                markerScale={selectedMarkerScale * 5}
                isSelected={slug || selectedMarker}
                isActive={slug}
                slug={slug}
            />
        </>
    );
};

export default Map;