import { useState, useEffect } from 'react'
import { createPortal } from 'react-dom';
import styles from './Overlay.module.sass'
import { usePreload, useWindowSize } from 'utils/hooks';
import styled, { keyframes } from 'styled-components';
import cls from 'classnames';

const animateImage = (from, to) => keyframes`
    0% {
        left: ${from.x}px;
        top: ${from.y}px;
        width: ${from.width}px;
        height: ${from.height}px;
    }
    100% {  
        left: ${to.x}px;
        top: ${to.y}px;
        width: ${to.width}px;
        height: ${to.height}px;
    }
`;

const AnimatedImage = styled.div`
    animation : ${props => animateImage(props.from, props.to)} 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) forwards;
`;

const ImageOverlay = ({ image, boundingRect, onCloseOverlay }) => {
    const windowSize = useWindowSize();
    const isLoaded = usePreload(image.pathFull);

    const [isClosing, setIsClosing] = useState(false)

    //const boundingRect = ref.current.getBoundingClientRect();

    //console.log(boundingRect)

    //if(image.height / image)

    useEffect(() => {

        if (!isClosing) return

        const timer = setTimeout(() => {
            onCloseOverlay();
        }, 200);

        return () => clearTimeout(timer);
    }, [isClosing, onCloseOverlay])

    if (!image) return false



    var scale = Math.min(
        windowSize.width / image.width,
        windowSize.height / image.height);

    const target = {
        x: (windowSize.width - image.width * scale) / 2,
        y: (windowSize.height - image.height * scale) / 2,
        width: image.width * scale,
        height: image.height * scale,
    }

  

    return (
        createPortal(
            (
                <div className={cls(styles.root, {closing: isClosing})} onClick={() => setIsClosing(true)}>
                   
                    <button
                        className="close"
                    >
                    </button>
                   
                    <AnimatedImage from={!isClosing ? boundingRect : target} to={!isClosing ? target : boundingRect} reverse={isClosing}>
                        <img src={image.path} />
                        {isLoaded &&
                            <img src={image.pathFull} />
                        }
                    </AnimatedImage>
                </div>
            ),
            document.getElementById("root")
        )
    );
};

export default ImageOverlay;