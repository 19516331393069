import { useEffect, useRef, useState } from "react";
import {
    useParams
} from 'react-router-dom';
import {
    useWindowSize
} from 'utils/hooks';

const closestIndex = (num, arr) => {
    let curr = arr[0], diff = Math.abs(num - curr);
    let index = 0;
    for (let val = 0; val < arr.length; val++) {
        let newdiff = Math.abs(num - arr[val]);
        if (newdiff < diff) {
            diff = newdiff;
            curr = arr[val];
            index = val;
        };
    };
    return index;
};

const useTouch = ({ locations, scaleLevels, mapSize }) => {
    const ref = useRef();
    const windowSize = useWindowSize();
    let { slug } = useParams();

    const [mapPosition, setMapPosition] = useState({
        x: 0,
        y: Math.max(windowSize.width, windowSize.height) * -0.1,
        scaleLevel: 0,
        scale: scaleLevels[0],
        rotateX: 45,
        selectedMarkerIndex: slug ? locations.findIndex(item => item.path === `/${slug}`) : null,
    });

    const [mapMovement, setMapMovement] = useState({
        x: 0,
        y: 0,
        scale: 0,
        isMoving: false,
        isPinching: false,
        isDragging: false
    })


    const startPoints = useRef([])

    useEffect(() => {
        ref.current.addEventListener("touchstart", onTouchStart, { passive: true });
        ref.current.addEventListener("touchmove", onTouchMove, { passive: true });
        ref.current.addEventListener("touchend", onTouchEnd, { passive: true });



        ref.current.addEventListener("mousedown", onDragStart, { passive: true });
        ref.current.addEventListener("mousemove", onDragMove, { passive: true });
        ref.current.addEventListener("mouseleave", onDragEnd, { passive: true });
        ref.current.addEventListener("mouseup", onDragEnd, { passive: true });

        const test = ref.current

        return () => {
            test.removeEventListener("touchstart", onTouchStart, { passive: true });
            test.removeEventListener("touchmove", onTouchMove, { passive: true });
            test.removeEventListener("touchend", onTouchEnd, { passive: true });

            test.removeEventListener("mousedown", onDragStart, { passive: true });
            test.removeEventListener("mousemove", onDragMove, { passive: true });
            test.removeEventListener("mouseleave", onDragEnd, { passive: true });
            test.removeEventListener("mouseup", onDragEnd, { passive: true });
        };

    }, [ref, mapMovement, mapPosition])


    const onDragStart = (event) => {

        if (event.target.classList.contains('image') && mapPosition.scale === scaleLevels[scaleLevels.length - 1]) {
            return false
        }

        const startPointsNew = [];

        console.log('onDragStart')

        startPointsNew.push({
            x: event.clientX,
            y: event.clientY
        })

        startPoints.current = startPointsNew

        let scaleNew = Math.min(mapPosition.scale, scaleLevels[scaleLevels.length - 2])

        console.log(scaleNew)

        setMapPosition({
            ...mapPosition,
            scale: scaleNew,
            rotateX: 45,
            selectedMarkerIndex: null,
        })

        setMapMovement({ ...mapMovement, isMoving: true, isDragging: true })
    }

    const onDragMove = (event) => {
        if (!mapMovement.isDragging) return

        console.log('onDragMove')

        let x = event.clientX - startPoints.current[0].x;
        let y = event.clientY - startPoints.current[0].y;

        let mapMovementNew = { ...mapMovement }

        if (Math.abs(x) > 5 || Math.abs(y) > 5) {
            mapMovementNew.x = x
            mapMovementNew.y = y
        }

        setMapMovement(mapMovementNew)
    }

    const onDragEnd = (event) => {
        if (!mapMovement.isDragging) return

        console.log('onDragEnd')

        const mapPositionNew = { ...mapPosition }
        mapPositionNew.x += mapMovement.x / mapPositionNew.scale
        mapPositionNew.y += mapMovement.y / mapPositionNew.scale

        setMapPosition(
            mapPositionNew
        )

        setMapMovement({
            x: 0,
            y: 0,
            scale: 0,
            isMoving: false,
            isPinching: false
        })
    }


    const onTouchStart = (event) => {

        if (event.target.classList.contains('image') && mapPosition.scale === scaleLevels[scaleLevels.length - 1]) {
            return false
        }

        const startPointsNew = [];

        console.log(event.touches)
        console.log(event.touches[0])

        if (event.touches[0]) {
            startPointsNew.push({
                x: event.touches[0].clientX,
                y: event.touches[0].clientY
            })
        }

        if (event.touches[1]) {
            startPointsNew.push({
                x: event.touches[1].clientX,
                y: event.touches[1].clientY
            })
        }

        let scaleNew = Math.min(mapPosition.scale, scaleLevels[scaleLevels.length - 2])

        setMapPosition({
            ...mapPosition,
            scale: scaleNew,
            rotateX: 45,
            selectedMarkerIndex: null,
        })




        startPoints.current = startPointsNew

        //onStartCallback();
    }

    //let scale = scaleLevels[mapPosition.scaleLevel];


    const onTouchMove = (event) => {
        console.log(mapPosition)
        //event.preventDefault();

        /*
        let touchingNew = { ...touching }
        touchingNew.pinch = null
        console.log(touchingNew)
        */

        let mapMovementNew = { ...mapMovement }



        if (startPoints.current.length === 2) {
            let { x: x1, y: y1 } = startPoints.current[0]
            let { x: x2, y: y2 } = startPoints.current[1]

            var distStart = Math.sqrt(Math.pow((x1 - x2), 2) + Math.pow((y1 - y2), 2));

            x1 = event.touches[0].clientX
            y1 = event.touches[0].clientY
            x2 = event.touches[1].clientX
            y2 = event.touches[1].clientY

            var distCurrent = Math.sqrt(Math.pow((x1 - x2), 2) + Math.pow((y1 - y2), 2));

            var pinch = distCurrent - distStart

            mapMovementNew.pinch = pinch
            mapMovementNew.distStart = distStart
            mapMovementNew.distCurrent = distCurrent

            // ** //

            let direction = pinch < 0 ? -1 : +1

            //let scaleNew = (pinch / 0.25 / windowSize.height) * scaleLevels[scaleLevels.length - 2] - scaleLevels[0];
            let scaleNew = (pinch / windowSize.height) * 4;


            //if (scaleNew < scaleLevels[0]) scaleNew = scaleLevels[0]
            //if (scaleNew > scaleLevels[scaleLevels.length - 2]) scaleNew = scaleLevels[scaleLevels.length - 2]


            //scaleNew -= mapPosition.scale


            mapMovementNew.scale = scaleNew;

            //mapPositionNew.scale += 

        } else {


            let x = event.touches[0].clientX - startPoints.current[0].x;
            let y = event.touches[0].clientY - startPoints.current[0].y;

            if (Math.abs(x) > 10 || Math.abs(y) > 10) {
                mapMovementNew.x = x
                mapMovementNew.y = y
            }

        }

        //test.current = { ...mapPositionNew }

        //console.log(touchingNew)

        setMapMovement(mapMovementNew)


        //onMoveCallback(event)
    }

    const onTouchEnd = (event) => {
        /*
        console.log(JSON.stringify(touching))
        const test = Object.assign({}, touching)
        console.log(test)
        */

        //onEndCallback(test);

        /*
        setTouching({
            isTouching: false,
            xStart: null,
            x: null,
            yStart: null,
            y: null,
        })
        */

        const mapPositionNew = { ...mapPosition }


        //let scaleLevelNew = mapPositionNew.scaleLevel;

        if (startPoints.current.length === 2) {

            let scaleNew = mapPositionNew.scale + mapMovement.scale;
            if (scaleNew < scaleLevels[0]) scaleNew = scaleLevels[0]
            if (scaleNew > scaleLevels[scaleLevels.length - 2]) scaleNew = scaleLevels[scaleLevels.length - 2]

            /*
            const needle = scaleLevelNew;

            const closest = [...scaleLevels].reduce((a, b) => {
                return Math.abs(b - needle) < Math.abs(a - needle) ? b : a;
            });


            scaleLevelNew = scaleLevels.findIndex(value => value === closest)
            */



            //mapPositionNew.scaleLevel = scaleLevelNew;
            mapPositionNew.scale = scaleNew
        } else {
            mapPositionNew.x += mapMovement.x / mapPositionNew.scale
            mapPositionNew.y += mapMovement.y / mapPositionNew.scale
        }

        setMapMovement({
            x: 0,
            y: 0,
            scale: 0,
            isMoving: false,
            isPinching: false,
            isDragging: false
        })

        setMapPosition(
            mapPositionNew
        )

        startPoints.current = []

    }

    const onClickZoom = (direction) => {

        const needle = mapPosition.scale;

        const closest = [...scaleLevels].reduce((a, b) => {

            return Math.abs(b - needle) < Math.abs(a - needle) ? b : a;
        });


        let scaleLevelNew = scaleLevels.findIndex(value => value === closest)
        const updatedScaleLevel = Math.min(Math.max(scaleLevelNew + direction, 0), scaleLevels.length - 2);


        setMapPosition({
            ...mapPosition,

            scale: scaleLevels[updatedScaleLevel],
            rotateX: 45,
            selectedMarkerIndex: null
        })

    }

    const mapPositionNew = { ...mapPosition }
    mapPositionNew.x += mapMovement.x / mapPositionNew.scale
    mapPositionNew.y += mapMovement.y / mapPositionNew.scale

    if (mapMovement.scale) {
        mapPositionNew.scale += mapMovement.scale

        if (mapPositionNew.scale < 0.2) mapPositionNew.scale = 0.2
        if (mapPositionNew.scale > scaleLevels[scaleLevels.length - 2] + 1) mapPositionNew.scale = scaleLevels[scaleLevels.length - 2] + 1

    }


    mapPositionNew.isMoving = mapMovement.x || mapMovement.y || mapMovement.scale

    return {
        ref,
        mapPosition: mapPositionNew,
        setMapPosition,
        onClickZoom,
        mapMovement
    }
}

export default useTouch