import React, { useState, useRef } from 'react';
import cls from 'classnames';
import LazyImage from 'components/LazyImage';
import styles from './Image.module.sass'
import ImageOverlay from 'components/ImageOverlay';

const SingleImage = ({ image, height }) => {
    const [ isSelected, setIsSelected ] = useState(false);
    const ref = useRef();

    return (
        <>
            <div onClick={() => setIsSelected(true)} ref={ref}>
                <LazyImage

                    image={image}
                    height={height}
                />
            </div>
            {isSelected &&
                <ImageOverlay
                    image={image}
                    boundingRect={ref.current.getBoundingClientRect()}
                    onCloseOverlay={() => setIsSelected(false)}
                />
            }
        </>
    )
}

const Image = ({ data }) => {

    console.log(data)

    const multiple = data.images.length > 1

    return (
        <section className={cls(styles.root, { multiple })}>
            {data.images.map((image, index) => (
                <SingleImage
                    key={index}
                    image={image}
                    height={multiple && 'container'}
                />

            ))}
            {data.caption &&
                <p>{data.caption}</p>
            }

        </section>
    );
};

export default Image;