import React, { useEffect, useMemo, useRef } from "react";
import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import Page from "components/Page";
import Location from "components/PageMap/Marker/Location";
import { StreetLabel } from "components/PageMap/Marker/Street";
import Loading from "components/Loading";
import styles from "./Page.module.sass";
import setTitle from "utils/setTitle";

//import unfilteredLocations from 'data/locations';

//const locations = unfilteredLocations.filter((location) => location.path)

const LocationTeaser = ({ location }) => (
  <>
    <div className="marker">
      <Location data={location} />
    </div>
    {location.address && (
      <div className="street">
        <div data-title={location.address}>
          <StreetLabel title={location.address} />
        </div>
      </div>
    )}
    <h2>
      {location.topTitle && location.topTitle}
      <span
        dangerouslySetInnerHTML={{
          __html: location.titleFormatted ?? location.title,
        }}
      />
    </h2>
  </>
);

const PageLocations = () => {
  const ref = useRef();

  useEffect(() => {
    setTitle("Orte");
  }, []);

  const { isLoading, error, data } = useQuery({
    queryKey: ["markers"],
    queryFn: () =>
      fetch(`${process.env.REACT_APP_PUBLIC_API_URL}markers`).then((res) =>
        res.json()
      ),
  });

  if (isLoading) return <Loading />;

  const locations = data.locations.filter((location) => location.path);

  locations.sort((a, b) => {
    var nameA = a.address?.toLowerCase(),
      nameB = b.address?.toLowerCase();
    if (nameA < nameB)
      //sort string ascending
      return -1;
    if (nameA > nameB) return 1;
    return 0; //default return value (no sorting)
  });

  console.log(ref.current?.getBoundingClientRect());

  return (
    <Page className={styles.root}>
      <h1>Orte</h1>
      <div className="locations">
        {locations.map((location, index) => (
          <div key={index}>
            {location.path ? (
              <Link to={location.path}>
                <LocationTeaser location={location} />
              </Link>
            ) : (
              <LocationTeaser location={location} />
            )}
          </div>
        ))}
      </div>
    </Page>
  );
};

export default PageLocations;
