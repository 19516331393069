const graves = [
    {
        marker: {
            x: 40.15,
            y: 43,
            type: 2
        }
    },
    {
        marker: {
            x: 40.65,
            y: 43.5,
            type: 1
        }
    },
    {
        marker: {
            x: 41.25,
            y: 43.5,
            type: 3
        }
    },
    {
        marker: {
            x: 41.85,
            y: 43.2,
            type: 2
        }
    },

    {
        marker: {
            x: 96.45,
            y: 34.6,
            type: 1
        }
    },
]

export default graves
