import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import cls from 'classnames';
import Page from 'components/Page';
import ContentRepeater from 'components/ContentRepeater';
import Location from 'components/PageMap/Marker/Location';
import { ArrowSimpleIcon } from 'components/Icons';
import styles from './Page.module.sass'
import { StreetLabel } from 'components/PageMap/Marker/Street';
import { useMemo } from 'react';
import { useState } from 'react';
import { useLanguage, useWindowSize } from 'utils/hooks';

const PageSpot = ({
    previewData,
    markerScale,
    isSelected,
    isActive,
    slug
}) => {
    //const [ data, setData ] = useState()

    console.log(isSelected, isActive)

    const windowSize = useWindowSize();


    const language = useLanguage();

    const { data: testData } = useQuery({
        queryKey: [language, 'markers', slug],
        queryFn: () =>
            fetch(`${process.env.REACT_APP_PUBLIC_API_URL}locations?slug=${slug}&lang=${language}`).then(res =>
                res.json()
            ),
        enabled: !!slug,
    })

    const data = testData ? testData : previewData


    console.log(data)

    useEffect(() => {
        //if (!isActive) {
        window.scroll(0, 0)
        //}
    }, [isActive])

    if (!data) return null

    return (
        <Page className={cls(styles.root, {
            selected: isSelected,
            active: isActive
        })}>
            <div className="marker" style={{ '--scale': markerScale, height: `${windowSize.clientHeight / 2}px` }}>
                {isActive &&
                    <Location
                        data={data}
                        animate={false}
                    />
                }
            </div>
            <h1>
                {data.path ? (
                    <Link to={data.path}>
                        {data.topTitle &&
                            data.topTitle
                        }
                        <span>{data.title}</span>
                    </Link>
                ) : (
                    <>
                        {data.topTitle &&
                            data.topTitle
                        }
                        <span>{data.title}</span>
                    </>
                )}
            </h1>
            {data.address &&
                <div className="street">
                    <StreetLabel title={data.address} large />
                </div>
            }
            {data.path &&
                <Link
                    to={data.path}
                    className="goto">
                    zur Seite
                    <ArrowSimpleIcon />
                </Link>
            }

            {(isActive && data.content) &&
                <>
                    <ContentRepeater data={data.content} />

                </>
            }
        </Page >
    );
};

export default PageSpot;