import React from 'react';
import styles from './Marker.module.sass'
import cls from 'classnames';
import { useImagePreload } from 'utils/hooks';

const Location = ({
    data,
    onClick,
    isSelected,
    animate = true,
    shouldStack
}) => {
    let {
        image,
        size,
        variant
    } = data.marker

    const isLoaded = useImagePreload(image.path)

    const style = image ? { backgroundImage: `url(${image.path})` } : null

    if (data.stacked && shouldStack) size = 2
    //if(grouped) size = 1
    //size = 1

    if (data.stacked?.children) {
        console.log(data.stacked?.children)
    }

    return (
        <div className={cls(styles.location, `size${size}`, {
            bordered: variant?.includes('bordered'),
            blue: variant?.includes('blue'),
            clickable: onClick,
            loaded: isLoaded || animate === false
        })}>
            <span
                className="image"
                onMouseDown={(event) => {
                    console.log('mousedown')
                    event.stopPropagation()
                }}
                onClick={onClick}
                style={style}
            >
                {data.stacked?.children === 500 &&
                    <span className="stack">{data.stacked.children + 1}</span>
                }

            </span>

        </div>
    );
};

export default Location;