import React, { useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import cls from 'classnames';
import { useLanguage, useOutsideClick } from 'utils/hooks';
import styles from './Header.module.sass'


const navigation = [
    {
        de: {
            title: 'Karte',
            path: '/'
        },
        en: {
            title: 'Map',
            path: '/en'
        },
    },
    {
        de: {
            title: 'Über',
            path: '/ueber'
        },
        en: {
            title: 'About',
            path: '/en/about'
        },
    },
    {
        de: {
            title: 'Orte',
            path: '/orte'
        },
        en: {
            title: 'Places',
            path: '/en/places'
        },
    },
    {
        de: {
            title: 'Kontakt',
            path: '/kontakt'
        },
        en: {
            title: 'Contact',
            path: '/en/contact'
        },
    },
    {
        de: {
            title: 'Datenschutz',
            path: '/datenschutz',
        },
        en: {
            title: 'Privacy',
            path: '/en/privacy'
        },

        variant: 'small'
    },
    {
        de: {
            title: 'Impressum',
            path: '/impressum',
        },
        en: {
            title: 'Imprint',
            path: '/en/imprint'
        },
        variant: 'small'
    }
]

const Header = () => {
    const [navIsOpen, setNavIsOpen] = useState(false)
    const ref = useOutsideClick(navIsOpen, () => setNavIsOpen(false))
    const location = useLocation();
    const language = useLanguage();



    const isActive = (path) => {
        if (location.pathname === path) return true

        const pages = [...navigation].splice(1).map(item => item[language].path)



        if (path === '/' && !pages.includes(location.pathname)) return true

        return false
    }

    return (
        <header className={cls(styles.root, { 'show-navigation': navIsOpen })}>
            <div ref={ref}>
                <button className="menu" onClick={() => setNavIsOpen(!navIsOpen)}>
                    <span />
                </button>
                <nav>
                    {navigation.map((item) => (
                        <Link
                            key={item[language].path}
                            to={item[language].path}
                            className={cls({ active: isActive(item[language].path), small: item.variant === 'small' })}
                            onClick={() => setNavIsOpen(false)}
                        >
                            {item[language].title}
                        </Link>
                    ))}
                </nav>
            </div>
        </header>
    );
};

export default Header;