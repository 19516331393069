import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import Page from 'components/Page';
import ContentRepeater from 'components/ContentRepeater';
import Loading from 'components/Loading';
import setTitle from 'utils/setTitle';

const PageDefault = () => {
    const location = useLocation();
    const slug = useMemo(() => (
        location.pathname.split('/')[1]
    ), [location])

    const { isLoading, data } = useQuery({
        queryKey: [slug],
        queryFn: () =>
            fetch(`${process.env.REACT_APP_PUBLIC_API_URL}page?slug=${slug}`).then(res =>
                res.json()
            )
    })

    useEffect(() => {
        data && setTitle(data.title)
    }, [data])

    if (isLoading) return <Loading />

    return (
        <Page>
            <h1>{data.title}</h1>
            <ContentRepeater data={data.content} />
        </Page>
    );
};

export default PageDefault;