import { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from 'react-router-dom'
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import Header from './components/Header';
import PageMap from './components/PageMap';
import PageLocations from './components/PageLocations';
import PageDefault from './components/PageDefault';
import detectSystem from 'utils/detectSystem';
import { CookieWrapper } from './contexts/CookieContext';
import './App.sass';
import CookieNotice from './components/CookieNotice';

const queryClient = new QueryClient();

function App() {
  const system = detectSystem();

  console.log(system)

  useEffect(() => {
    if (system) {
      document.documentElement.classList.add(...system)
    }
  }, [system])

  useEffect(() => {

    let resizeTimer;

    window.addEventListener("resize", () => {
      document.body.classList.add("resizing");
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(() => {
        //console.log('enable anmation')
        document.body.classList.remove("resizing");
      }, 300);
    });
  }, [])

  return (
    <CookieWrapper>
      <QueryClientProvider client={queryClient}>
        <Router>
          <Header />
          <span className="line" />
          <main>
            <Routes>
              <Route path="/en" element={<PageMap />}>
                <Route
                  path=":slug/*"
                />
              </Route>

              <Route path="/ueber" element={<PageDefault />} />
              <Route path="/orte" element={<PageLocations />} />
              <Route path="/kontakt" element={<PageDefault />} />
              <Route path="/impressum" element={<PageDefault />} />
              <Route path="/test" element={<PageDefault />} />
              <Route path="/datenschutz" element={<PageDefault />} />
              <Route path="/" element={<PageMap />}>
                <Route
                  path=":slug/*"
                />
              </Route>
            </Routes>
          </main>
          <CookieNotice />
        </Router>
      </QueryClientProvider>
    </CookieWrapper>
  );
}

export default App;



