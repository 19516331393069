function getOS() {
    let userAgent = window.navigator.userAgent.toLowerCase(),
        macosPlatforms = /(macintosh|macintel|macppc|mac68k|macos)/i,
        windowsPlatforms = /(win32|win64|windows|wince)/i,
        iosPlatforms = /(iphone|ipad|ipod)/i,
        os = null;

    if (macosPlatforms.test(userAgent)) {
        os = "macos";
    } else if (iosPlatforms.test(userAgent)) {
        os = "ios";
    } else if (windowsPlatforms.test(userAgent)) {
        os = "windows";
    } else if (/android/.test(userAgent)) {
        os = "android";
    } else if (!os && /linux/.test(userAgent)) {
        os = "linux";
    }

    return os;
}


const detectSystem = () => {
    if (typeof window === 'undefined') return []

    const ua = navigator?.userAgent

    const system = []

    const os = getOS();

    if(os) system.push(os);

    if (/iPad|iPhone|iPod/.test(ua)) {
        system.push('safari')
    } else if (navigator.maxTouchPoints &&
        navigator.maxTouchPoints > 2 &&
        /MacIntel/.test(navigator.platform)) {
        system.push('safari')
    } else if (navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1 && navigator.userAgent.indexOf('Edg') === -1) {
        system.push('safari')
    }

    if (navigator.userAgent.indexOf('Firefox') !== -1) {
        system.push('firefox')
    }

    return system
}

export default detectSystem