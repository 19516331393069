import React from 'react';
import Location from './Location';
import Street from './Street';
import Tree from './Tree';
import Grave from './Grave';
import styles from './Marker.module.sass'
import cls from 'classnames';

const components = {
    location: Location,
    street: Street,
    tree: Tree,
    grave: Grave
}

const Marker = ({
    type,
    data,
    isVisible = 1,
    isSelected,
    onClick,
    shouldStack = false
}) => {
    const Component = components[type]
    //const { marker } = data

    console.log('render')

    let { x, y } = data.marker

    if (data.stacked?.type === 'child' && shouldStack) {

        x = data.stacked.x + (data.stacked.index + 1) * 0.06
        y = data.stacked.y - (data.stacked.index + 1) * 0.2

        //x = data.stacked.x + (data.stacked.index + 1) * 0
        //y = data.stacked.y - (data.stacked.index + 1) * 0.3
    }

    const style = {
        left: `${x}%`,
        top: `${y}%`,

    }

    if (isSelected) style['--map-marker-scale'] = 'var(--map-marker-scale-selected)';

    return (
        <div
            className={cls(styles.root, {
                hidden: !isVisible
            })}
            style={style}
        >
            <Component
                data={data}
                isSelected={isSelected}
                onClick={() => onClick(data)}
                shouldStack={shouldStack}
            />
        </div>
    );
};



export default Marker