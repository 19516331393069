import React from 'react';
import cls from 'classnames';
import styles from './Marker.module.sass'

const Tree = ({ data }) => {

    const { type } = data.marker

    return (
        <svg viewBox="0 0 7.46 22.26" className={cls(styles.grave)}>
            {type === 1 &&
                <>
                    <defs>
                        <linearGradient id="d1" data-name="Unbenannter Verlauf 3" x1="-1.1" y1="12.68" x2="6.62" y2="3.48" gradientTransform="translate(0 24.11) scale(1 -1)" gradientUnits="userSpaceOnUse">
                            <stop offset="0" stopColor="#b6a895" />
                            <stop offset=".63" stopColor="#9b8e7c" />
                        </linearGradient>
                        <linearGradient id="e1" data-name="Unbenannter Verlauf 2" x1="1.94" y1="14.03" x2="6.92" y2="14.03" gradientTransform="translate(0 24.11) scale(1 -1)" gradientUnits="userSpaceOnUse">
                            <stop offset="0" stopColor="#d7c9b5" />
                            <stop offset=".63" stopColor="#c9bba8" />
                        </linearGradient>
                    </defs>
                    <g id="b" data-name="Layer 2">
                        <g id="c" data-name="Layer 1-2">
                            <path d="m2.78,9.24c.91.54,1.67,1.29,2.21,2.19l-.31,10.83-4.14-1.5.36-10.89c.35-.69,1.19-.96,1.88-.63" fill="url(#d1)" />
                            <path d="m4.71,8.91c-.26-.14-.55-.19-.84-.15l-1.94.35c.29-.04.58,0,.84.13.91.54,1.67,1.29,2.21,2.19l1.94-.33c-.54-.91-1.3-1.66-2.21-2.19" fill="url(#e1)" />
                            <polygon points="4.98 11.43 6.92 11.1 6.62 21.93 4.67 22.26 4.98 11.43" fill="#b6a895" />
                        </g>
                    </g>
                </>
            }
            {type === 2 &&
                <>
                    <defs>
                        <linearGradient id="d2" data-name="Unbenannter Verlauf 3" x1="-.54" y1="9.01" x2="5.96" y2="3.55" gradientTransform="translate(0 24.11) scale(1 -1)" gradientUnits="userSpaceOnUse">
                            <stop offset="0" stopColor="#b6a895" />
                            <stop offset=".63" stopColor="#9b8e7c" />
                        </linearGradient>
                        <linearGradient id="e2" data-name="Unbenannter Verlauf 3" x1="3.14" y1="10.8" x2="4.45" y2="9.7" xlinkHref="#d2" />
                    </defs>
                    <g id="b" data-name="Layer 2">
                        <g id="c" data-name="Layer 1-2">
                            <polygon points="1.13 13.13 1.56 13.41 3.51 13.08 3.07 12.8 1.13 13.13" fill="#b6a895" />
                            <polygon points="1.56 13.41 2.09 13.87 4.04 13.53 3.51 13.08 1.56 13.41" fill="#c9bba8" />
                            <path d="m4.49,13.64l-1.94.33c-.13.02-.26,0-.37-.06h-.09l2-.34s.05.04.08.05c.12.05.24.07.37.06" fill="#d7c9b5" />
                            <polygon points="3.87 14.53 4.16 14.64 6.11 14.3 5.81 14.2 3.87 14.53" fill="#d7c9b5" />
                            <polygon points="4.16 14.64 6.11 14.3 6.34 14.99 4.4 15.32 4.16 14.64" fill="#b6a895" />
                            <polygon points="4.66 15.92 6.6 15.58 6.49 21.92 4.55 22.26 4.66 15.92" fill="#b6a895" />
                            <polygon points="4.4 15.32 4.66 15.92 6.6 15.58 6.34 14.99 4.4 15.32" fill="#c9bba8" />
                            <path d="m3.44,14.04l.42.49.29.11.24.68.26.6-.1,6.34-3.69-1.58v-7.12l.26-.43.44.28.53.46h.09c.12.06.26.08.4.06.1,0,.2.03.28.09.07.03.14.07.2.12l.38-.1Z" fill="url(#d2)" />
                            <path d="m3.47,14.08h0l1.52-.28s-.08-.08-.13-.12c-.09-.05-.2-.06-.3-.05l-1.95.33c.09,0,.17.02.25.06.07.03.14.07.21.12" fill="url(#e2)" />
                            <polygon points="3.44 14.04 3.87 14.53 5.81 14.2 5.38 13.7 3.44 14.04" fill="#c9bba8" />
                        </g>
                    </g>
                </>
            }
            {type === 3 &&
                <>
                    <defs>
                        <linearGradient id="d3" data-name="Unbenannter Verlauf 4" x1="2.5" y1="19.47" x2="6.75" y2="20.22" gradientTransform="translate(0 24.11) scale(1 -1)" gradientUnits="userSpaceOnUse">
                            <stop offset="0" stopColor="#d7c9b5" />
                            <stop offset=".8" stopColor="#c9bba8" />
                        </linearGradient>
                        <linearGradient id="e3" data-name="Unbenannter Verlauf 3" x1="-2.13" y1="13.9" x2="8.65" y2="9.98" gradientTransform="translate(0 24.11) scale(1 -1)" gradientUnits="userSpaceOnUse">
                            <stop offset="0" stopColor="#b6a895" />
                            <stop offset=".63" stopColor="#9b8e7c" />
                        </linearGradient>
                    </defs>
                    <g id="b" data-name="Layer 2">
                        <g id="c" data-name="Layer 1-2">
                            <path d="m6.67,5.36h-.13c-.18-.07-.32-.23-.36-.42-.19-.99-.76-1.86-1.6-2.42-.2-.1-.43-.13-.65-.1l-1.77.32c.22-.04.45,0,.66.09.83.54,1.4,1.39,1.59,2.36.04.19.18.35.37.42h.07c.22.09.37.28.42.51l1.77-.31c-.05-.23-.21-.41-.42-.5" fill="url(#d3)" />
                            <polygon points="5.32 6.17 7.09 5.86 7.46 21.96 5.7 22.26 5.32 6.17" fill="#b6a895" />
                            <path d="m.29,4.1h.37s.07,0,.09-.08c.02-.04.04-.08.07-.12l-.57.1H.25" fill="#d7c9b5" />
                            <path d="m2.82,2.83c.83.54,1.4,1.39,1.59,2.36.04.19.18.35.36.42h.13c.22.09.37.28.42.51l.38,16.14-5.1-2.34L0,4.42c.27-.61.62.05.76-.24.53-1.21,1.3-1.69,2.06-1.35" fill="url(#e3)" />
                        </g>
                    </g>
                </>
            }
        </svg>
    );
};

export default Tree;