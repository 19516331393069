import React from 'react';
import cls from 'classnames';
import styles from './Text.module.sass'

const Text = ({data}) => {
    return (
        <section className={cls(styles.root, 'richtext')} dangerouslySetInnerHTML={{__html: data.text}}>
            
        </section>
    );
};

export default Text;