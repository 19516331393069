import React from 'react'
import Images from './Images';
import Text from './Text'
import Video from './Video'
import styles from './Content.module.sass'

const components = {
    text: Text,
    images: Images,
    video: Video
}

const ContentRepeater = ({data}) => {    
    const contents = data.map((item, index) => {
        const Component = components[item.component]

        return (
            <Component 
                key={index} 
                data={item.data} />
        )
    })
    
    return (
        <div className={styles.root}>
            {contents}
        </div>
    );
};

export default ContentRepeater;