import { useState, useEffect, useRef } from "react"

const usePreload = (image, shouldLoad = true) => {
    const [ state, setState ] = useState(null)

    const test = useRef(0);

    const i = useRef();
   

    useEffect(() => {

        //console.log('preload' + test.current)

        if (shouldLoad && state === null) {
            
            setState('loading')
            
            test.current ++
            console.log('preload' + test.current)

            i.current = new Image()

            i.current.onload = () => {
                setState('loaded')
            };

            i.current.src = image;
        }

        if (!shouldLoad && state === 'loading') {

            console.log('cancel')
            setState(null)
            i.current.src = '';
        }

    }, [state, shouldLoad]);

    return state === 'loaded'
}

export default usePreload
