import React from 'react';
import cls from 'classnames';
import styles from './Page.module.sass'

const Page = ({className,  children}) => {
    return (
        <div className={cls(styles.root, className)}>
            {children}
        </div>
    );
};

export default Page;