import React, { useLayoutEffect, useState } from 'react';
import styles from './Cookie.module.sass'
import { useCookieContext } from 'contexts/CookieContext';
import { Link } from 'react-router-dom';

function setCookie(cname, cvalue, mins) {
    var d = new Date();
    d.setTime(d.getTime() + (mins * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}



const CookieNotice = () => {

    const { cookies, saveCookies } = useCookieContext();

    const [cookiesState, setCookiesState] = useState({
        essential: true,
        external: true
    })

    const clickCheckbox = (event, type) => {
        console.log(type)
        event.preventDefault();
        setCookiesState({ ...cookiesState, [type]: !cookiesState[type] })
    }

    const acceptAll = () => {
        saveCookies({
            essential: true,
            external: true
        })
    }

    const save = () => {
        saveCookies(cookiesState)
    }

    //console.log(cookies)

    if (cookies.essential) return null

    return (
        <div className={styles.cookie}>
            <p>Diese Webseite nutzt Cookies. Einige davon sind essenziell andere von externen Medien.<br />
                Durch den Besuch dieser Internetseite stimmen Sie der Nutzung von Cookies gemäß unserer <Link to="/datenschutz">Datenschutzbestimmungen</Link> zu.
            </p>
            <div>
                <div>
                    <label

                        className={cookiesState.essential ? 'checked' : ''}
                    >
                        <input type="checkbox" checked={cookiesState.essential} />Essenziell</label>
                    <label
                        onClick={(event) => clickCheckbox(event, 'external')}
                        className={cookiesState.external ? 'checked' : ''}
                    ><input type="checkbox" />Externe Medien</label>
                </div>
                <div>
                    <button onClick={() => acceptAll()}>Alle akzeptieren</button>
                    <button onClick={() => save()}>Speichern</button>
                </div>
            </div>

        </div>
    );
};

export default CookieNotice;