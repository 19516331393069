import { useState, useEffect } from "react";
import useIsBrowser from "./useIsBrowser";

const useWindowSize = () => {
    const isBroser = useIsBrowser();

    const [windowSize, setWindowSize] = useState({
        clientWidth: isBroser ? document.documentElement.clientWidth : undefined,
        clientHeight: isBroser ? document.documentElement.clientHeight : undefined,
        width: isBroser ? window.innerWidth : undefined,
        height: isBroser ? window.innerHeight : undefined
    });

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                clientWidth: document.documentElement.clientWidth,
                clientHeight: document.documentElement.clientHeight,
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        handleResize();

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize)
        };
    }, []);

    return windowSize;
}

export default useWindowSize