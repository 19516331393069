import { createContext, useContext, useState, useRef, useEffect, useLayoutEffect } from 'react';
const CookieContext = createContext();


const setCookie = (cname, cvalue, mins) => {
    var d = new Date();
    d.setTime(d.getTime() + (mins*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

const getCookie = (cname) => {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export function CookieWrapper({ children }) {
    const [ cookies, setCookies ] = useState({
        essential: false,
        external: false
    });

    useLayoutEffect(() => {
        const savedCookie = getCookie('_tp_settings');

        //console.log(savedCookie)
        
        if(savedCookie) setCookies(JSON.parse(savedCookie))
    }, [setCookies])

    const saveCookies = (cookies) => {
        setCookies(cookies)

        setCookie(
            '_tp_settings',
            JSON.stringify(cookies),
            60 * 24 * 365
        )
    }

    return (
        <CookieContext.Provider value={{ cookies, saveCookies}}>
            {children}
        </CookieContext.Provider>
    );
}

export function useCookieContext() {
    return useContext(CookieContext);
}