import React from 'react';
import cls from 'classnames';
import { useIsVisible, useImagePreload } from 'utils/hooks';
import styles from './Image.module.sass'

const LazyImage = ({ image, height }) => {
    const { ref, isVisible } = useIsVisible();
    const isLoaded = useImagePreload(image.path, isVisible)

    const style = {};
    if(image.color) style.backgroundColor = image.color
    if(!height) style.paddingBottom = `${image.height/image.width*100}%`

    return (
        <div
            ref={ref}
            className={cls(styles.root, {loaded: isLoaded})}
            style={style}
        >
            <img 
                src={image.path}
                loading="lazy"
                alt={image.alt}
            />
        </div>
    );
};

export default LazyImage;