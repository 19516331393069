import { useRef, useEffect } from "react";

const useOutsideClick = (active = true, callback) => {
    const ref = useRef()

    useEffect(
        () => {
            const listener = (event) => {
                if (!ref.current || ref.current.contains(event.target) || !active) {
                    return;
                }
                callback();
            };
            
            document.addEventListener("mousedown", listener);
            document.addEventListener("touchstart", listener);
            
            return () => {
                document.removeEventListener("mousedown", listener);
                document.removeEventListener("touchstart", listener);
            };
        },
        [ref, callback, active]
    );

    return ref
}

export default useOutsideClick