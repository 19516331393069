import React from 'react';

const Logo = () => {
  return (
    <svg viewBox="0 0 59 59.99">
      <g fill="currentColor">
        <path d="m58.53.35L55.38,0c-1.11.06-2.22.17-3.33.41-.29.06-.47.29-.47.53l.06,10.34,7.24-2.57.12-7.83c0-.23-.17-.47-.47-.53" />
        <path d="m18.52,57.66l4.67-3.27.41,1.69s.99,3.91,1.05,3.91l12.73-2.92s10.34-4.56,10.28-4.61l-1.64-3.56,1.87-.82,1.93.82,3.21-6.02,1.46-5.67.23-4.32-1.63.29-.7-2.51c.06-.12-1.17-4.03-1.17-4.03.23-.06.53-.18.76-.35l-4.09-13.73-3.27,1.17s-.99-3.39-1.05-3.39l-3.62.94s-1.11-3.15-1.17-3.15-8.06.76-8.29.64l-.12-.47-1.05-.64c-.12-.06-.29-.06-.41.06l-3.21-1.99-2.86,3.62c-.29-.06-.58-.12-.82-.23l-9.23,2.8c-.06,0-.12.06-.06.12l1.11,3.21-.47.53-3.68-.53-1.52,2.74s-3.91.06-3.91.12l.64,3.97c0,.64-.06,1.28-.12,1.93l-2.28.82v.06c-.17,1.46-2.34,3.27-2.28,3.33l1.23,2.51L0,31.37c-.06,0,2.51,5.61,2.51,5.61l.99,3.27-1.81.76c-.06,0,.29,1.05.29,1.23l.12,1.23v.06l16.42,14.14Z" />
      </g>
    </svg>
  );
};

export default Logo;