import React from 'react';
import styles from './Loading.module.sass'

const Loading = () => {
    return (
        <span className={styles.root}>
            Loading...
        </span>
    );
};

export default Loading;