import React from 'react';
import cls from 'classnames';
import styles from './Marker.module.sass'

/*
  <svg viewBox="0 0 100 20">
            <path d="M4.9,1c-.3,2.02-1.88,3.61-3.9,3.92V15.1c2.02,.3,3.61,1.88,3.92,3.9H95.1c.3-2.02,1.88-3.61,3.9-3.92V4.9c-2.02-.3-3.61-1.88-3.92-3.9H4.9Z" fill="none" stroke="currentColor" strokeWidth="2" />
        </svg>
        */

const StreetLabel = ({ title, large }) => (
    <span
        className={cls(styles['street-label'], { large })}
        data-title={title}
    >
        <span>
            {title}
            <span className="top" />
            <span className="bottom" />
            <span className="left" />
            <span className="right" />
            <span className="corner corner1" />
            <span className="corner corner2" />
            <span className="corner corner3" />
            <span className="corner corner4" />
        </span>
    </span>
)

export { StreetLabel }

const Street = ({ data, large }) => {
    return (
        <div className={styles.street}>
            <StreetLabel title={data.title} large={large} />
        </div>
    );
};

export default Street;