import React from 'react';
import cls from 'classnames';
import styles from './Video.module.sass'
import { useCookieContext } from 'contexts/CookieContext';
import { Link } from 'react-router-dom';

const Video = ({ data }) => {

    const { cookies, saveCookies } = useCookieContext();

    return (
        <section className={cls(styles.root)}>
            <div>
                {cookies.external ? (
                    <>
                        {(data.type === 'vimeo') && (
                            <iframe
                                src={`https://player.vimeo.com/video/${data.id}`}
                                frameBorder="0"
                                allow="autoplay; fullscreen; picture-in-picture"
                                allowFullScreen />
                        )}
                        {(data.type === 'youtube') && (
                            <iframe
                                src={`https://www.youtube.com/embed/${data.id}`}
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen />
                        )}
                    </>
                ) : (
                    <p>
                        Mit dem Abspielen dieses Videos akzeptieren Sie die <Link to="/datenschutz">Datenschutzbedingungen</Link> für externe Cookies.<br /><br />
                        <span onClick={() => saveCookies({ essential: true, external: true })}>Video abspielen</span>

                    </p>
                )}



            </div>
            {data.caption &&
                <p>{data.caption}</p>
            }
        </section>
    );
};

export default Video;